// used for i18n
export default {
  index: {
    ko: "/",
    de: "/",
  },
  "blog/articles/[uid]": {
    ko: "/blog/articles/[uid]",
    de: "/blog/articles/[uid]",
  },
  blog: {
    ko: "/blog",
    de: "/blog",
  },
  properties: {
    ko: "/properties",
    de: "/properties",
  },
};
