import pages from "./pages";
import ko from "./ko.json";
import de from "./de.json";
import numberFormats from "@/config/i18n/numberFormats.ts";

export default defineI18nConfig(() => {
  return {
    locale: process.env.CURRENT_DOMAIN,
    fallbackLocale: process.env.CURRENT_DOMAIN,
    messages: {
      ko,
      de,
    },
    datetimeFormats: {
      ko: {
        longDate: {
          year: "numeric",
          month: "long",
          day: "numeric",
        },
        month: "long",
      },
      de: {
        longDate: {
          year: "numeric",
          month: "long",
          day: "numeric",
        },
        month: "long",
      },
    },
    numberFormats,
    parsePages: false,
    pages,
  };
});
